<template>
  <ws-link
      :href="entity.external_link || null "
      :new-window="entity.external_link"
  >
  <v-sheet
      @click="handleOpenNews"
      :color="backgroundColor"
      class="wsRoundedHalf py-6 mb-4"
      :class="[{'pointer' : !entity.external_link && !!entity.alias && entity.has_content }]"
      :dark="darkCondition"
  >

    <v-sheet
        color="transparent" class="px-4"  width="100%"
    >
      <h5 :style="`color : ${ !(entity.background_color ==='medium' || entity.background_color ==='accent') ? wsDARKLIGHT : 'white'}`"
          class="mb-2 font-weight-regular"
      >
        {{ PARSE_DATE(entity.date_published , false ,true) }} / {{ $t(entity.category_value ) }}
      </h5>
      <h4 :style="`color : ${!darkCondition ? wsDARKER : 'white'}`" v-html="entity.title" />
      <ws-text-viewer class="nunito" font-size="14" :value="entity.preview" image-height="158px" />

      <h5 v-if="entity.has_content && !!entity.alias"
          class="pointer hoverUnderline mt-n2 d-flex align-center"
          :style="`color: ${wsATTENTION}`" >
        {{ !entity.external_link ?  $t('MoreDetails') : $t('Goto') }}

        <v-icon class="ml-1" small :color="wsATTENTION">
          {{ !entity.external_link ? 'mdi-arrow-right' : 'mdi-open-in-new' }}
        </v-icon>
      </h5>


    </v-sheet>


  </v-sheet>
  </ws-link>
</template>

<script>
export default {
  name: "newsPreviewSupport",
  props : {
    entity : {
      type : Object,
      default() { return {} }
    }
  },
  computed : {
    routerTag() {
      if (this.entity.external_link) {
        return 'a'
      }

      if (this.entity.has_content) {
        return 'router-link'
      }
      return 'span'
    },
    darkCondition() {
      return !['light' , 'medium_light'].includes(this.entity.background_color) && !this.isNotification
    },
    isNotification() {
      return this.entity.type === 'notification'
    },
    backgroundColor() {

      switch (this.entity.background_color) {
        case 'light' : return this.wsLIGHTCARD
        case "medium_light" : return this.wsLIGHTACCENT
        case "medium" : return this.wsDARKLIGHT
        case "dark" : return this.wsACCENT
        case "accent"  : return this.wsATTENTION
        default : return this.wsLIGHTCARD
      }
    },
  },
  methods : {
    handleOpenNews() {
      if (!this.entity.external_link && !!this.entity.alias && this.entity.has_content) {
        this.$emit("open-news", {alias : this.entity.alias, title : this.entity.title})
      }
    }
  }
}
</script>

<style scoped>

</style>