<template>

  <!-- News Content -->
  <v-sheet v-if="!loading" color="transparent" max-width="782"  >

    <!-- Preview Image -->
    <v-img
        v-if="entity.img"
        :height="!SM ? '334' : '200'"
        :src="entity.img"
        transition="xxx"
    />

    <div class="d-flex" :class="[{'px-3' : SM}]">
      <v-sheet class="mx-auto pt-4" color="transparent" max-width="448" width="100%">
        <!-- Date, Reading time , socials -->
        <div class="d-flex justify-space-between align-center mb-4">
          <h5 class="wsDARKLIGHT font-weight-regular">
            {{ PARSE_DATE(entity.date_published , false ,true).toLowerCase() }} · {{ $t('Read') }} {{ readTime }} {{ $t('min') }}
          </h5>
          <!-- Socials -->
          <div class="d-flex">
            <v-btn href="https://www.facebook.com/westudy.ua" target="_blank" small icon >
              <v-icon  :size="24"  :color="wsDARKLIGHT">mdi-facebook</v-icon>
            </v-btn>
            <v-btn href="https://t.me/westudy_ua/" target="_blank" small icon>
              <v-sheet :height="20" :width="20" class="d-flex align-center justify-center" style="border-radius: 50%; padding-left: 1px" dark :color="wsDARKLIGHT">
                <v-icon :size="13"  style="transform: rotate(-30deg); padding-bottom: 1px; padding-left: 3px " >mdi-send</v-icon>
              </v-sheet>
            </v-btn>
            <v-btn href="https://www.instagram.com/westudy_ua/" target="_blank" small icon >
              <v-sheet :height="20" :width="20" class="d-flex align-center justify-center" style="border-radius: 50%;" dark :color="wsDARKLIGHT">
                <v-icon :size="13"  >mdi-instagram</v-icon>
              </v-sheet>
            </v-btn>
            <v-btn href="https://www.youtube.com/@westudy_ua" target="_blank" small icon>
              <v-icon :size="24" :color="wsDARKLIGHT">mdi-youtube</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- Title -->
        <h3 style=" line-height: 1.2; "
            :style="`color : ${wsDARKER};`"
            class="mb-4">
          {{ entity.title }}
        </h3>

        <!-- Subtitle -->
        <h3 id="subtitle" class="wsACCENT"  style="line-height: 1.5; font-weight: 300; "
        >
          {{ entity.subtitle }}
        </h3>
        <ws-text-viewer
            class="mt-5"
            :value="entity.content"
            font-size="16"
            is-public
            video-height="248"
        />
      </v-sheet>
    </div>

    <v-divider :style="`border-color : ${wsBACKGROUND}`"></v-divider>

    <div class="d-flex pb-8 mt-2"
         :class="[{'px-3' : SM}]"
    >
      <v-sheet class="mx-auto pt-4" color="transparent" max-width="448" width="100%">
        <support-navigation
            @select-item="handleOpenNews"
            title="ReadMore"
            :items="recentNews"
        />
      </v-sheet>
    </div>


<!--        v-for="(item , i) in recentNews" :key="i"-->
<!--        :to="!item.external_link && !!item.alias  ? localeLink('news/' + item.alias) : null"-->
<!--        :href="item.external_link || null"-->
<!--        new-window-->


  </v-sheet>
  <div v-else class="fill-height d-flex align-center justify-center">
    <div style="width: 100px">
      <v-progress-linear rounded indeterminate :color="wsACCENT" />
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import supportNavigation from "@/modules/tech_support/components/UI/supportNavigation";

export default {
  name: "SingleNews",
  props: ['alias'],
  components : {
    supportNavigation
  },
  data() {
    return {
      displayPopup : false,
      closePopup : false,
      readTime : 0,
      entity : {},
      recentNews : [],
      categoryNews : [],
      windowWidth : 2000,
      loading : false
    }
  },
  watch : {
    alias() {
      this.windowWidth = window.innerWidth
      this.getNews()
      this.displayPopup = true
    },
    LANG() {
      this.getNews()
    }
  },
  methods : {
    ...mapActions('news', [ 'GET_NEWS_MAIN_ONE_PUBLIC', 'GET_NEWS_MAIN_PUBLIC']),

    scrollUp() {
      setTimeout(() => {
        let view = document.getElementById('support_chat_content_view')
        view.scrollTop = 0;
      })

    },
    handleOpenNews(item) {
      if (item.alias && !item.url) {
        this.$emit('open-news' , { alias : item.alias , title : item.text })
      }
    },

    prepareStyles() {
      let text = document.body.querySelectorAll('p')
      let h2 = document.body.querySelectorAll('h2')

      let allText = this.entity.subtitle + ' ' + this.entity.title
      this.readTime = Math.ceil(allText.split(/\s+/).length / 190);
      let content = this.$refs.text
      if ( content ) {
        let container = content.$el
        if ( container ) {
          if ( container.innerText ) {
            let allText = this.entity.subtitle + ' ' + this.entity.title + ' ' +  container.innerText.trim()
            this.readTime += Math.ceil(allText.split(/\s+/).length / 190);
          }
        }
      }
      text.forEach((el) => {
        el.style.paddingBottom = '23px'
        el.style.color = this.wsDARKER
      })
      h2.forEach(el => {

        if (!el.id ) {
          el.style.fontSize = '16px'
          el.style.lineHeight = '22px'
          el.style.color = this.wsDARKER
          el.style.paddingBottom = '24px'
        }

      })



    },

    async getNews() {

      this.loading = true
      this.scrollUp()
      let result =  await this.GET_NEWS_MAIN_ONE_PUBLIC(this.alias)
      this.loading = false
      if ( !result ) {
        return
      }



      this.entity = result.news
      result.recent = result.recent.filter(el => el.alias || el.external_link)
      result.recent.forEach(item => {
        item.text = item.title
        item.icon = 'mdi-newspaper-variant-outline'
        if (item.external_link) {
          item.icon = 'mdi-open-in-new'
          item.url = item.external_link
          if ( item.external_link.includes('youtube.com') ) {
            item.icon = 'mdi-youtube'
          }
        }
        item.icon_color = this.wsDARK
      })

      this.recentNews = result.recent

      setTimeout(() => {
        this.prepareStyles()
      }, 300)

    }
  },
  async mounted(){
    this.windowWidth = window.innerWidth
    await this.getNews()
    this.displayPopup = true
  }
}
</script>

<style lang="scss" scoped>

.main_section {
  width:1100px
}
.sideNews  {
  text-decoration: none;
  line-height: 1.5 ;
  font-size: 0.75rem;
  font-weight: 400;

  font-family: sans-serif;
  color: #393939;
}
</style>