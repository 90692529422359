<template>

  <div v-if="!loading" class="fill-height">
    <div v-show="!selectedNews" style="background: #ffffff" class="pa-4">
      <news-preview-support
          v-for="(item,i) in news" :key="i"
          @open-news="openNews"
          :entity="item"
          compact
      />
    </div>
    <news-view-support
        v-if="selectedNews"
        @open-news="openNews"
        :alias="selectedNews"
    />

  </div>
  <div v-else class="fill-height d-flex align-center justify-center">
    <div style="width: 100px">
      <v-progress-linear rounded indeterminate :color="wsACCENT" />
    </div>
  </div>

</template>

<script>
import newsPreviewSupport from "@/modules/tech_support/components/pages/UI/newsPreviewSupport";
import newsViewSupport from "@/modules/tech_support/components/pages/UI/newsViewSupport";
import {mapActions} from "vuex";

export default {
  name: "supportHome",
  components : {
    newsPreviewSupport,
    newsViewSupport
  },
  props : {
    returnTrigger : {
      type : Number
    },
    scrollPosition : {
      type : Number
    },
  },
  data() {
    return {
      news : [],
      isLast : false,
      offset : 0,
      selectedCategory : 'All',
      selectedNews : null,
      scrollBuffer : null,
      loading : false,
    }
  },
  watch : {
    returnTrigger() {
      this.handleReturn()
    },
  },
  methods : {
    ...mapActions('news', [
          'GET_NEWS_MAIN_PUBLIC'
        ]
    ),
    handleReturn() {
      // Single Hiearchy
      this.selectedNews = null
      this.$emit('update-header' , {} )
      let view = document.getElementById('support_chat_content_view')
      this.loading = true
      setTimeout(()=> {
        this.loading = false
        setTimeout(()=> {
          view.scrollTop = this.scrollBuffer || 0;
          this.scrollBuffer = null
        },1)
      },300)



    },

    openNews({alias,title}) {
      this.scrollBuffer = this.scrollPosition
      this.selectedNews = alias
      let link = this.localeLink(`news/${alias}`)
      this.$emit('update-header' ,
          { name : title , displayReturn : true , wide : true , link: link } )
    },
    async getNews(loadMore = false) {
      this.loading = true
      if ( !loadMore ) {
        this.news = []
      }
      let data = {
        category_id : this.selectedCategory,
        offset : this.news.length
      }

      let result = await this.GET_NEWS_MAIN_PUBLIC(data)
      this.loading = false
      if ( !result ) {
        return
      }
      if ( !loadMore ) {
        this.news = result.news
      } else {
        if ( result.news.length > 0 )
          result.news.forEach(el => {
            this.news.push(el)
          })
      }
      this.isLast = result.is_last || false

    },
    checkNavigationValue() {
      if ( !this.$store.state.support.navigationValue ) {
        return
      }
      let item = this.news.find(el => el.alias === this.$store.state.support.navigationValue )
      if ( !item ) {
        return
      }
      this.$emit('flush-navigation-value')
      this.$store.state.support.navigationValue = null
      this.openNews({alias : item.alias , title : item.title})
    }
  },
  async mounted() {
    await this.getNews()
    this.checkNavigationValue()
  }
}
</script>

<style scoped>

</style>